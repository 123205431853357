import { getUserInfo } from '@api';
import React from 'react'
import { Navigate } from 'react-router-dom';

interface Props {
    children: React.ReactNode;
}

export const CoreMiddleware: React.FC<Props> = ({ children }) => {
    const currentURL = window.location.pathname;

    try {
        var currentUser = getUserInfo().token;
        if (!currentUser) {
            return <Navigate to={`/auth/login?returnUrl=${currentURL}`} />;
        }
    } catch (error) {
        return <Navigate to={`/auth/login?returnUrl=${currentURL}`} />;
    }

    return (
        <>
            {children}
        </>
    )
}

