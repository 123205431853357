import { ENDPOINTS, httpService, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { User } from "@models";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  employeeId: number;
  callback?: () => void;
}

const AttachUser: React.FC<Props> = ({ callback, employeeId }) => {
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const fetchUsers = useFetch<PaginatedResult<User>>(
    { endPoint: ENDPOINTS.users },
    new PaginatedResult<User>()
  );

  const onSubmit = async (data: any, e: any) => {
    if (!employeeId) return;

    setIsLoading(true);

    let emp = {
      id: employeeId,
      userId: data.userId,
    };

    const res = await httpService(ENDPOINTS.attachUser).update(employeeId, emp);
    if (res.status === 204) {
      callback?.();
    }
    setIsLoading(false);
    e.target.reset();
  };
  return (
    <>
      <h4>Attach User</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>User</label>
              <select
                className="form-control select"
                name="userId"
                ref={register({ required: true })}
              >
                <option></option>
                {fetchUsers?.data?.items?.map((r: User, i: any) => {
                  return (
                    <option key={i} value={r.id}>
                      {r.userName}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        <input
          type="submit"
          name="time"
          className="btn btn-danger"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : "Attach"}
        />
      </form>
    </>
  );
};

export default AttachUser;
