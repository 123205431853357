import { ENDPOINTS, httpService, PaginatedResult } from '@api';
import { useFetch } from '@hooks';
import { PayrollGroup } from '@models';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';


interface Props {
    callback?: () => void
}

const AddPayrollGroup: React.FC<Props> = ({ callback }) => {
    const { register, handleSubmit, errors } = useForm();

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: any, e: any) => {
        setIsLoading(true);

        const payrollGroup: PayrollGroup = {
            id: 0,
            createdAt: '',
            name: data.name
        }

        const res = await httpService(ENDPOINTS.payrollGroups).post(payrollGroup);
        if (res.status === 201) {
            Swal.fire({ icon: 'success', text: 'New payroll group has been successfully registered.', showConfirmButton: false });
        }

        callback?.();
        setIsLoading(false);
        e.target.reset();
    }
    return (
        <>
            <h4>Add New Payroll Group</h4>
            <hr />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label>Payroll Group Name</label>
                            <div className="col-12">
                                <input type="text" className="form-control" placeholder="Enter payroll group name" name='name' ref={register({ required: true })} />
                            </div>
                        </div>
                    </div>
                </div>

                <input type="submit" name="time" className="btn btn-primary" disabled={isLoading} value={isLoading ? "Please wait..." : "Register"} />
            </form>
        </>
    )
}

export default AddPayrollGroup