import { ENDPOINTS, httpService } from "@api";
import { IncomeSource } from "@models";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  callback?: () => void;
  incomeSource?: IncomeSource;
}

const AddIncomeSource: React.FC<Props> = ({ callback, incomeSource }) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);

    let aType: Partial<IncomeSource> = {
      name: data.name,
    };

    if (incomeSource?.id) {
      await httpService(ENDPOINTS.incomeSources).update(incomeSource.id, aType);
    } else {
      await httpService(ENDPOINTS.incomeSources).post(aType);
    }

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>{incomeSource?.id ? "Edit" : "Add"} Income Source</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Source of Income</label>
              <div className="col-12">
                <input
                  type="text"
                  defaultValue={incomeSource?.name ?? ""}
                  className="form-control"
                  name="name"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.name && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        
        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={
            isLoading
              ? "Please wait..."
              : incomeSource?.id
              ? "Update"
              : "Register"
          }
        />
      </form>
    </>
  )
};

export default AddIncomeSource;
