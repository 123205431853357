import { JobOffer } from "@models";
import { JobApplicationStatus, JobEducationLevel } from "@viewModels";

export class JobApplication {
    id: number;
    jobOfferId: number;
    jobOffer: JobOffer;
    fullName: string;
    phone: string;
    email: string;
    attachmentPaths: string[];
    dateOfBirth: string;
    nationality: string;
    countryOfResidence: string;
    city: string;
    address: string;
    remarks: string;
    status: JobApplicationStatus;
    createdAt: string;
    age?: number;
    qualification?: JobEducationLevel;

}
