import { ENDPOINTS, httpService } from "@api";
import {
  EmployeeAttendanceDetails,
  EmployeeLookUp,
  PaperHeader,
} from "@components";
import {
  Action,
  ComplexHeader,
  DateHelpers,
  ExportData,
  Table,
  TableVerticalConfigs,
} from "@shared";
import { AttendanceByBranch } from "@viewModels";
import { useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Employee } from "@models";

type Search = { employeeName: string; date: string };

const EmployeeMonthly = () => {
  const MySwal = withReactContent(Swal);

  const [data, setData] = useState<AttendanceByBranch[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useState<Search>({
    employeeName: "",
    date: "",
  });

  const headers: ComplexHeader[] = [
    { key: "employeeName", title: "Name" },
    { key: "startDate", title: "start Date", format: "date" },
    { key: "endDate", title: "end Date", format: "date" },
    { key: "totalHours", title: "total Hours" },
    { key: "avgHoursPerDay", title: "avg Hours Per Day" },
    { key: "noOfPresentDays", title: "Present Days" },
    { key: "noOfAbsentDays", title: "Absent Days" },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (e: any) => {
        MySwal.fire({
          showConfirmButton: false,
          allowOutsideClick: false,
          showCloseButton: true,
          width: 800,
          html: (
            <EmployeeAttendanceDetails
              employeeCode={e?.employeeId}
              startDate={e?.startDate}
              endDate={e.endDate}
            />
          ),
        });
      },
      title: "Details",
      color: "info",
    },
  ];

  const onExport: ExportData = {
    data: () => Promise.resolve(data),
    fileName: "Employee Attendance",
  };

  const onPrint = (element: HTMLElement) => {
    var subElement = element.querySelectorAll(":scope > div")[0];
    subElement.classList.remove("d-none");

    var subElement02 = element.querySelectorAll(":scope > div")[1];
    subElement02.classList.add("d-none");

    let table = element.getElementsByTagName("table")[0];
    table.classList.remove("table-striped");
  };

  const findEmployee = () => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 1000,
      html: (
        <EmployeeLookUp
          callback={(e: Employee) =>
            setSearchParams((prev) => ({
              ...prev,
              employeeName: e.name,
            }))
          }
        />
      ),
    });
  };

  const handleSearch = async () => {
    if (!searchParams.employeeName) return;

    setIsLoading(true);

    let params = new URLSearchParams();
    searchParams.date && params.set("start_date", searchParams.date);

    const res = await httpService(
      ENDPOINTS.attendanceByEmployee,
      params
    ).getById(searchParams.employeeName);
    if (res.status === 200) {
      setData(res.data);
    }
    setIsLoading(false);
  };

  return (
    <div className="col-8 offset-1 mt-3" id="reportcontents">
      <PaperHeader title="Leave Balances Report" />
      <div className="d-flex justify-content-between">
        <div className="col-8">
          <div className="row gap-2 mb-1">
            <div className="col">
              <input
                type="month"
                className="form-control"
                onChange={(e) =>
                  setSearchParams((prev) => ({
                    ...prev,
                    date: DateHelpers.toMMddyyyy(e.target.value, true),
                  }))
                }
              />
            </div>
            <div className="col">
              <input
                value={searchParams.employeeName}
                type="text"
                className="form-control"
                style={{ cursor: "pointer" }}
                onClick={findEmployee}
              />
            </div>
          </div>
        </div>
        <div className="col-auto float-end ms-auto">
          <button
            disabled={isLoading}
            className="btn btn-sm btn-outline-info rounded-circle m-1 float-right"
            onClick={handleSearch}
          >
            <RiSearchLine />
          </button>
          <TableVerticalConfigs
            exportAsXSLS={onExport}
            onPrint={{
              ref: "reportcontents",
              prePrint: onPrint,
            }}
          />
        </div>
      </div>
      <Table
        class="table table-striped table-hover mb-0"
        data={data}
        headers={headers}
        actions={actions}
        isFetchingPage={isLoading}
        showCounter
        paginationClass="row mt-3"
      />
    </div>
  );
};

export default EmployeeMonthly;
