import { ENDPOINTS, httpService } from "@api";
import {
  AddAppraisalTemplate,
  AllowancesReport,
  AllowancesSummaryReport,
  AnnualLeavesReport,
  AttendanceMain,
  CoA,
  ConfigurationsDashboard,
  DeductionsReport,
  EmployeeYearlyAppraisal,
  FilterPayroll,
  JobOfferApplicants,
  LeaveBalancesReport,
  LeaveSummaryReport,
  ListAppraisals,
  ListAppraisalTemplates,
  ListBranches,
  ListCycles,
  ListDepartments,
  ListDesignations,
  ListEmployees,
  ListEvaluationCriteria,
  ListJobOffers,
  ListKRAs,
  ListPayroll,
  ListPayrollGroups,
  ListRoles,
  ListUsers,
  NewRole,
  OverTimesReport,
  PayrollDetails,
  PayrollPreview,
  PayrollPreviewE,
  Profile,
  Unauthorized,
  YearlyAppraisalTemplateUtilization,
} from "@components";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./layout/Dashboard";
import Header from "./layout/Header";
import SideBar from "./layout/SideBar";

function App() {
  return (
    <div className="main-wrapper">
      <Header />
      <SideBar />
      <div className="page-wrapper">
        {/* <Dashboard /> */}
        <Routes>
          <Route path="/configurations" element={<ConfigurationsDashboard />} />
          {/* =======User Routes=========== */}
          <Route path="/users" element={<ListUsers />} />
          <Route path="/roles" element={<ListRoles />} />
          <Route path="/roles/new" element={<NewRole />} />

          {/* =======Employee Routes=========== */}
          <Route path="/employees" element={<ListEmployees />} />
          <Route path="/employees/profile/:id" element={<Profile />} />

          {/* =======Payroll Routes=========== */}
          <Route path="/payrolls" element={<ListPayroll />} />
          <Route
            path="/payrolls/payrollGroups"
            element={<ListPayrollGroups />}
          />
          <Route path="/payrolls/filter" element={<FilterPayroll />} />
          <Route path="/payrolls/preview" element={<PayrollPreview />} />
          <Route
            path="/payrolls/preview/:branchId/:payrollPrepId"
            element={<PayrollPreviewE />}
          />
          <Route path="/payrolls/details/:id" element={<PayrollDetails />} />

          {/* =======Department Routes=========== */}
          <Route path="/departments" element={<ListDepartments />} />
          <Route path="/designations" element={<ListDesignations />} />

          {/* =======Branch Routes=========== */}
          <Route path="/branches" element={<ListBranches />} />

          {/* =======Recruitment Routes=========== */}
          <Route path="/recruitment/offers" element={<ListJobOffers />} />
          <Route
            path="/recruitment/applicants/:id"
            element={<JobOfferApplicants />}
          />

          {/* =======Performance Routes=========== */}
          <Route path="/kras" element={<ListKRAs />} />
          <Route
            path="/evaluationCriteria"
            element={<ListEvaluationCriteria />}
          />
          <Route
            path="/appraisal/template/list"
            element={<ListAppraisalTemplates />}
          />
          <Route
            path="/appraisal/template/new"
            element={<AddAppraisalTemplate />}
          />
          <Route path="/appraisal/cycles" element={<ListCycles />} />
          <Route path="/appraisal/list/:id" element={<ListAppraisals />} />

          {/* =======Reports Routes=========== */}
          <Route
            path="/reports/leaves/leaveBalances"
            element={<LeaveBalancesReport />}
          />
          <Route
            path="/reports/leaves/summary"
            element={<LeaveSummaryReport />}
          />
          <Route
            path="/reports/annual-leave"
            element={<AnnualLeavesReport />}
          />
          <Route path="/reports/allowances/branch" element={<AllowancesReport />} />
          <Route path="/reports/allowances/summary" element={<AllowancesSummaryReport />} />
          <Route path="/reports/deductions" element={<DeductionsReport />} />
          <Route path="/reports/attendance" element={<AttendanceMain />} />
          <Route path="/reports/overtimes" element={<OverTimesReport />} />
          <Route
            path="/reports/appraisals/yearly"
            element={<EmployeeYearlyAppraisal />}
          />
          <Route
            path="/reports/template/utilization"
            element={<YearlyAppraisalTemplateUtilization />}
          />

          {/* =======Main Routes=========== */}
          <Route path="/unauthorized" element={<Unauthorized />} />
          {/* <Route path="/" element={<Dashboard />} /> */}

          <Route path="/coa" element={<CoA />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
