export enum BACKEND_env {
    Production = "Production",
    SandBox = "Sand Box",
    Local = "Local"
}

export const getBaseURL = (currentEnv: BACKEND_env) => {
    switch (currentEnv) {
        case BACKEND_env.Production:
            return "https://hrapi.kobciyye.com/api/";
            break;
        case BACKEND_env.SandBox:
            return "https://hrapiamaltest.kobciyye.com/api/";
            break;
        case BACKEND_env.Local:
            return "https://localhost:7119/api/";
            break;
        default:
            return "https://localhost:7119/api/";
            break;
    }
}