import { ENDPOINTS, PagingOptions } from "@api";
import { UserInfoViewModel } from "@viewModels";
import axios from "axios";
import Swal from "sweetalert2";
import crypto from 'crypto-js';
import { BACKEND_env, getBaseURL } from "./baseURL";

export const BASE_CURRENCY = "$";
let BASE_URL = "";
export let CURRENT_BACKEND = BACKEND_env.Production;

if (process.env.NODE_ENV === "development") {
  BASE_URL = getBaseURL(CURRENT_BACKEND);
} else {
  BASE_URL = getBaseURL(CURRENT_BACKEND);
}

export interface HTTPServiceOptions {
  ignorePagination: boolean
}

export const httpService = <T>(endpoint: ENDPOINTS, options?: PagingOptions | URLSearchParams | string, serviceOptions?: HTTPServiceOptions) => {
  const query = options ?? (serviceOptions?.ignorePagination ? new PagingOptions(1, -1) : new PagingOptions());

  const formatedQuery =
    query instanceof PagingOptions ? query.format() : query instanceof URLSearchParams ? query.toString() : query;

  const url = BASE_URL + endpoint;

  requestInterceptors();
  responseInterceptors();

  return {
    getAll: () =>
      axios.get(url + `?${formatedQuery}`),
    getById: (id: any) =>
      axios.get(url + "/" + id + `?${formatedQuery}`),
    getBlob: () =>
      axios.get(url, { responseType: 'blob' }),
    post: (newRecord: T) =>
      axios.post(url, newRecord),
    update: (id: number, updatedRecord: T) =>
      axios.put(url + "/" + id, updatedRecord),
    delete: (id: number) =>
      axios.delete(url + "/" + id),
  };
};

const requestInterceptors = () => {
  axios.interceptors.request.use((request: any) => {
    // add auth header with jwt if account is logged in and request is to the api url
    const token = getUserInfo().token;
    const isLoggedIn = token ? true : false;
    const isApiUrl = request?.url?.startsWith(BASE_URL);

    if (isLoggedIn && isApiUrl) {
      request!.headers!.common!.Authorization = `Bearer ${token}`;
    }

    return request;
  });
};

const responseInterceptors = () => {
  axios.interceptors.response.use(
    (response) => {
      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          showConfirmButton: false,
          text: "The record has been added successfully.",
        });
      }
      if (response.status === 204) {
        Swal.fire({
          icon: "success",
          showConfirmButton: false,
          text: "The record has been Updated successfully.",
        });
      }

      return response;
    },
    (error) => {
      // if (axios.isCancel(error)) {
      //   console.log("Error is: ", error);
      //   return error;
      // }
      if (!axios.isCancel(error) && !error.response) {
        Swal.fire({
          title: "Oops",
          text: "Unable to reach the server. Please check your reachability to the server.",
          icon: "error",
          toast: true
        });
        return error;
      }
      if (error.response.status === 401) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          timer: 3000,
          icon: "error",
          showConfirmButton: false,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          },
          text: error?.response?.data?.message ?? "Your seesion has expired, you will be redirected to login page.",
        });

        !error?.response?.data && logout();
      }
      if (error.response.status === 403) {
        window.location.replace("/unauthorized");
      }
      if (error.response.status === 400) {
        Swal.fire({
          toast: true,
          position: 'bottom-end',
          timer: 3000,
          icon: "error",
          showConfirmButton: false,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }, text: `${error?.response?.data?.message ?? "An unprecedented error occurred, please try again."}`,
        });
        // Swal.fire({
        //   text: `${error?.response?.data?.message ?? "An unprecedented error occurred, please try again."}`,
        //   showConfirmButton: false,
        //   icon: "error",
        //   toast: true
        // });
      }
      if (error.response.status === 404) {
        Swal.fire({
          toast: true,
          position: 'bottom-end',
          timer: 3000,
          icon: "error",
          showConfirmButton: false,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          },
          text: "Requested object not found",
        });
        // Swal.fire({
        //   title: "Oops",
        //   text: "Requested object not found",
        //   icon: "error",
        //   toast: true
        // });
      }
      if (error.response.status === 500) {
        Swal.fire({
          toast: true,
          position: 'bottom-end',
          timer: 3000,
          icon: "error",
          showConfirmButton: false,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          },
          text: "An unprecedented error occurred, please try again.",
        });
        // Swal.fire({
        //   title: "Oops",
        //   text: "An unprecedented error occurred, please try again.",
        //   icon: "error",
        //   toast: true
        // });
      }
      return error;
    }
  );
};

export const logout = () => {
  const currentURL = window.location.pathname;
  localStorage.removeItem("sys_user");
  window.location.assign(`/auth/login?returnUrl=${currentURL}`);
  return;
};

export const getUserInfo = () => {
  let decryptedData = new UserInfoViewModel();

  const currentUser = JSON.parse(localStorage.getItem('sys_user') ?? '""') as string;
  if (currentUser) {
    // Decrypt
    const bytes = crypto.AES.decrypt(currentUser, 'secret key 123');
    decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8)) as UserInfoViewModel;
  }

  return decryptedData;
}

