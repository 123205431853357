import { ENDPOINTS } from "@api";
import { useFetch } from "@hooks";
import React, { useEffect, useState } from "react";

class TestEmployee {
  id: number;
  name: string;
  description: string;
  parentId: number | null;
  parent: TestEmployee;
  children: TestEmployee[];
  segmentId: number | null;
  isLastNode: boolean;
}

const CoA = () => {
  const [data, setData] = useState<{ name: string; data: any[] }[]>([]);

  const fetchData = useFetch<TestEmployee[]>({ endPoint: ENDPOINTS.coa }, []);

  useEffect(() => {
    if (fetchData?.data.length > 0) {
      let tables: { name: string; data: any[] }[] = [];

      fetchData?.data.forEach((table, idx) => {
        const res = getDepth(table);
        const ra = constructTable(table, res, true, 0, ["description"]);
        tables.push({ name: table.name, data: ra });
        //   console.log(`Table : `, ra);
      });

      setData(tables);
    }
  }, [fetchData.isFetching]);

  const getDepth = (data: any): any => {
    if (Array.isArray(data)) {
      // if arr is an array, recurse over it
      return 1 + Math.max(...data.map(getDepth));
    }
    if (data.children && data.children.length) {
      // if arr is an object with a children property, recurse over the children
      return 1 + Math.max(...data.children.map(getDepth));
    }
    return 0;
  };

  const constructTable = (
    data: any,
    depth: number,
    buildHeaders: boolean = true,
    recursionCounter = 0,
    metaData: string[] = []
  ): any[] => {
    let rows: any[] = [];

    if (typeof data !== "object") return rows;
    if ("children" in data === false) return rows;
    if (data?.children === null || data?.children?.length < 1) return rows;

    // Build the headers
    if (buildHeaders) {
      let collumnsBasedOnDepth = [...Array(depth)].map(
        (_, idx) => `Part ${1 + idx}`
      );
      rows.push([...collumnsBasedOnDepth, ...metaData]);
    }

    // Build the body
    data?.children.forEach((element: any, idx: number) => {
      let metaDataFieldsRows: any[] = [];

      if (element?.isLastNode) {
        let rowData: any[] = [];
        metaData?.forEach((el) => rowData.push(element[el]));
        metaDataFieldsRows = rowData;
      } else {
        metaDataFieldsRows = [];
      }

      const row = [element?.name, ...metaDataFieldsRows];

      let prefixCells = [...Array(recursionCounter)].map((_, idx) => "");
      row.unshift(...prefixCells);

      let howManyBlank = depth + metaData?.length - row.length;
      howManyBlank > 0 &&
        row.push(...[...Array(howManyBlank)].map((_, idx) => ""));

      rows.push(row);

      if (element?.children !== null) {
        rows.push(
          ...constructTable(
            element,
            depth,
            false,
            recursionCounter + 1,
            metaData
          )
        );
      }
    });

    return rows;
  };

  const buildHeaders = (idx: number): JSX.Element[] => {
    const res = getDepth(fetchData?.data[idx]);
    let headers: JSX.Element[] = [];
    if (res > 0) {
      let temp = Array(res)
        .fill(0)
        .map((_, idx) => 1 + idx)
        .map((c) => <th>Part {c}</th>);
      headers = [...temp, <th>description</th>];
    } else {
      headers = [<th>description</th>];
    }

    return headers;
  };

  const buildBody = (idx: number): JSX.Element[] => {
    const res = getDepth(fetchData?.data[idx]);
    let rows: JSX.Element[] = [];

    let blankCells = Array(res - 1)
      .fill(0)
      .map((c) => <td></td>);

    fetchData?.data[idx]?.children.forEach((element, idxx) => {
      const x = element?.isLastNode ? (
        <td>{element?.description}</td>
      ) : (
        <td></td>
      );
      rows.push(
        <tr>
          <td>{element?.name}</td>
          {blankCells}
          {x}
        </tr>
      );
      rows.push(...buildNestedRows(element?.children, 1, res - 2));
    });
    return rows;
  };

  const buildNestedRows = (arr: any[], depth: number, blankRows: number) => {
    let rows: JSX.Element[] = [];

    let prefixCells = Array(depth)
      .fill(0)
      .map((_, idx) => 1 + idx)
      .map((c) => <td></td>);

    let blankCells =
      blankRows < 0
        ? []
        : Array(blankRows)
            .fill(0)
            .map((c) => <td></td>);

    if (Array.isArray(data)) {
      arr.forEach((element, idxx) => {
        const descriptionCol = element?.isLastNode ? (
          <td>{element?.description}</td>
        ) : (
          <td></td>
        );
        rows.push(
          <tr>
            {prefixCells}
            <td>{element?.name}</td>
            {blankCells}
            {descriptionCol}
          </tr>
        );
        if (element?.children !== null)
          rows.push(
            ...buildNestedRows(element?.children, depth + 1, blankRows - 1)
          );
      });
    }

    return rows;
  };

  const buildRow = (row: any[]): JSX.Element[] => {
    const tds: JSX.Element[] = [];
    console.log("rows are: ", row);
    row?.forEach((cell: any) => tds.push(<td>{cell}</td>));

    return tds;
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="d-flex justify-content-between mb-1">
          <div className="col-4">
            <h3 className="page-title">Chart of Accounts</h3>
          </div>
        </div>
      </div>
      {/* <div className="col-12 mt-3" id="reportcontents">
        {fetchData?.data?.map((table, idx) => {
          return (
            <>
              <div
                key={`ti${idx}`}
                className="d-flex justify-content-center mt-5"
              >
                <h3>
                  {table?.name} - {table?.description}
                </h3>
              </div>
              <table key={`ta${idx}`} className="table table-sm">
                <thead>
                  <tr key={`tr${idx}`}>{buildHeaders(idx)}</tr>
                </thead>
                <tbody key={`tb${idx}`}>{buildBody(idx)}</tbody>
              </table>
            </>
          );
        })}
      </div> */}
      <div className="col-12 mt-3" id="reportcontents">
        {data?.map((table, idx) => {
          return (
            <>
              <div
                key={`ti${idx}`}
                className="d-flex justify-content-center mt-5"
              >
                <h3>{table?.name}</h3>
              </div>
              <table className="table table-sm">
                <thead>
                  <tr>
                    {table?.data[0]?.map((cell: string, idx: number) => {
                      return <th key={idx}>{cell}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {table?.data?.map((row: any[], idx) => {
                    return <tr key={idx}>{buildRow(table?.data[idx + 1])}</tr>;
                  })}
                </tbody>
              </table>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default CoA;
