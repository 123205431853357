import { ENDPOINTS, httpService } from "@api";
import { useFetch } from "@hooks";
import { Role } from "@models";
import { PermissionGroup } from "@viewModels";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

interface Props {
  role?: Role;
  callback?: () => void;
}

const NewRole: React.FC<Props> = ({ callback, role }) => {
  const selectedRole = useLocation().state as Role;

  const { register, handleSubmit, errors } = useForm();
  const navigate = useNavigate();

  const [permissions, setPermissions] = useState<PermissionGroup[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState(-1);

  const fetchPermissions = useFetch<PermissionGroup[]>(
    { endPoint: ENDPOINTS.permissions },
    []
  );

  useEffect(() => {
    setPermissions(fetchPermissions.data);
  }, [fetchPermissions.isFetching]);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);
    var role = new Role();
    if (selectedRole) {
      // Update Op.
      role = {
        id: selectedRole.id,
        description: data?.description,
        permissions: Object.keys(data)
          .filter((f) => data[f] && f !== "roleName" && f !== "description")
          .map((f) => f.split("-").join(".")),
      } as Role;

      if (role?.permissions?.length < 1) {
        return;
      }

      const res = await httpService(ENDPOINTS.roles).update(
        selectedRole.id,
        role
      );
      if (res.status === 204) {
        Swal.fire({
          icon: "success",
          text: "Selected Role has been successfully updated.",
          showConfirmButton: false,
        });
      }
    } else {
      // Insert Op.
      role = {
        name: data.roleName,
        description: data?.description,
        permissions: Object.keys(data)
          .filter((f) => data[f] && f !== "roleName" && f !== "description")
          .map((f) => f.split("-").join(".")),
      } as Role;

      if (role?.permissions?.length < 1) {
        return;
      }

      const res = await httpService(ENDPOINTS.roles).post(role);
      if (res.status === 201) {
        Swal.fire({
          icon: "success",
          text: "New Role has been successfully registered.",
          showConfirmButton: false,
        });
      }
    }

    setIsLoading(false);
    navigate("/roles");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="content container-fluid">
        <div className="row align-items-center">
          <div className="col">
            <h3 className="page-title">
              {" "}
              {selectedRole ? "Update Role: " : "Create New Role"}
            </h3>
          </div>
        </div>

        <div className="row mb-3 mt-5">
          <div className="col-2">
            {/* <h6 className='pt-3'> {selectedRole ? "Update Role: " : "Create New Role"}</h6> */}
          </div>
          <div className="col-3">
            <input
              type="text"
              defaultValue={selectedRole?.name}
              disabled={selectedRole && true}
              placeholder="Enter Role Name..."
              className="form-control col-3"
              id="roleName"
              name="roleName"
              ref={register({ required: true })}
            />
            <span className="text-danger">
              {errors.roleName && <span>This field is required</span>}
            </span>
          </div>
          <div className="col-3">
            <input
              type="text"
              defaultValue={selectedRole?.description}
              placeholder="Description"
              className="form-control col-3"
              id="description"
              name="description"
              ref={register({ required: true })}
            />
            <span className="text-danger">
              {errors.description && <span>This field is required</span>}
            </span>
          </div>
          <div className="col-2 offset-1">
            {!isLoading ? (
              // <input type="submit" className="btn btn-dark" value={state ? "Update Role" : "Create Role"} />
              <input
                type="submit"
                className="btn btn-dark"
                value={selectedRole ? "Update Role" : "Create Role"}
              />
            ) : (
              <button className="btn btn-dark" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                Loading...
              </button>
            )}
          </div>
        </div>

        <div
          className="accordion accordion-flush mt-5"
          id="accordionFlushExample"
        >
          {permissions.map((module, i) => {
            return (
              <div key={i} className="accordion-item">
                <h2 className="accordion-header" id={`module${i}`}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#iconAccordion${i}`}
                    aria-expanded="false"
                    aria-controls={`iconAccordion${i}`}
                  >
                    {module?.title}
                  </button>
                </h2>
                <div
                  id={`iconAccordion${i}`}
                  className="container accordion-collapse collapse"
                  aria-labelledby={`module${i}`}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="row mt-3 mb-3">
                    {module.permissions.map((permission, n) => {
                      return (
                        <div
                          key={permission?.value}
                          className="form-check col-3 mt-1 mb-1"
                        >
                          <input
                            type="checkbox"
                            defaultChecked={
                              selectedRole?.permissions?.includes(
                                permission?.value
                              ) && true
                            }
                            className="form-check-input"
                            id={permission?.value}
                            name={permission?.value.split(".").join("-")}
                            ref={register()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={permission?.value}
                          >
                            {permission?.title}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </form>
  );
};

export default NewRole;
