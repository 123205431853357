import { ENDPOINTS, httpService } from "@api";
import { JobEducationLevel } from "@viewModels";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  offerId: number;
  callback?: () => void;
}

const SubmitApplication: React.FC<Props> = ({ callback, offerId }) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any, e: any) => {
    if (!offerId) return;

    setIsLoading(true);

    const formData = new FormData();

    for (let i = 0; i < data.files.length; i++) {
      formData.append("files", data.files[i]);
    }

    formData.append("jobOfferId", offerId.toString());
    formData.append("fullName", data.fullName);
    formData.append("phone", data.phone);
    formData.append("email", data.email);
    formData.append("remarks", data.remarks);
    formData.append("dateOfBirth", data.dateOfBirth);
    formData.append("nationality", data.nationality);
    formData.append("countryOfResidence", data.countryOfResidence);
    formData.append("city", data.city);
    formData.append("address", data.address);
    formData.append("qualification", data.qualification);

    const res = await httpService(ENDPOINTS.applications).post(formData);
    if (res.status === 201) {
      callback?.();
      e.target.reset();
      setIsLoading(false);

      let storage = localStorage.getItem("application");
      if (typeof storage === "string") {
        let res = JSON.parse(storage) as [];
        if (!res.isEmpty()) {
          localStorage.setItem(
            "application",
            JSON.stringify([...res, { id: offerId }])
          );
          return;
        }
      }
      localStorage.setItem("application", JSON.stringify([{ id: offerId }]));
      window.location.reload();
    }

    setIsLoading(false);
  };

  return (
    <>
      <h4>Submit Application</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <div className="form-group">
              <label>Full Name</label>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control"
                  name="fullName"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.fullName && <span>This field is required</span>}
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="form-group">
              <label>Phone</label>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.phone && <span>This field is required</span>}
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="form-group">
              <label>Email Address</label>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.email && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-4">
            <div className="form-group">
              <label>Date Of Birth</label>
              <div className="col-12">
                <input
                  type="date"
                  className="form-control"
                  name="dateOfBirth"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.dateOfBirth && <span>This field is required</span>}
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="form-group">
              <label>Nationality</label>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control"
                  name="nationality"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.nationality && <span>This field is required</span>}
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="form-group">
              <label>Country Of Residence</label>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control"
                  name="countryOfResidence"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.countryOfResidence && (
                  <span>This field is required</span>
                )}
              </span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label>City</label>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control"
                  name="city"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.city && <span>This field is required</span>}
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label>Address</label>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.address && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label>Upload</label>
              <div className="col-12">
                <input
                  type="file"
                  className="form-control"
                  name="files"
                  multiple
                  accept=".pdf"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.file && <span>This field is required</span>}
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label>Qualification</label>
              <select
                className="form-control select"
                name="qualification"
                ref={register({ required: true })}
              >
                <option></option>
                {Object.keys(JobEducationLevel).map((d, i) => {
                  return (
                    <option key={i} value={d}>
                      {d.replace("_", " ")}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="remarks">Further Remarks</label>
              <textarea
                className="form-control mb-4"
                style={{ height: "200px" }}
                name="remarks"
                id="remarks"
                aria-label="With textarea"
                ref={register({ required: true })}
              ></textarea>
              <span className="text-danger">
                {errors.remarks && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>

        <input
          style={{ width: "100px" }}
          type="submit"
          name="time"
          className="btn btn-dark"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : "Submit"}
        />
      </form>
    </>
  );
};

export default SubmitApplication;
