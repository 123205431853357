import { JobOffer } from '@models'
import { Markup } from 'interweave'
import React from 'react'
import ReactQuill from 'react-quill'

interface props {
    offer: JobOffer
}

const JobDetail = ({ offer }: props) => {
    return (
        <>
            <h3>{offer.title}</h3>
            <hr />

            <div className="row mb-5 mt-3">
                <div className="d-flex justify-content-around">
                    <span>Expires At: <strong>{offer.expiryDate}</strong> </span>
                    <span>Location: <strong>{offer.location}</strong> </span>
                </div>
                <div className="d-flex justify-content-around">
                    <span>level Of Education: <strong>{offer.levelOfEducation.replace('_', ' ')}</strong> </span>
                    <span>Type: <strong>{offer.type.replace('_', ' ')}</strong> </span>
                </div>
            </div>

            <ReactQuill readOnly theme="bubble" value={offer.description} />
        </>
    )
}

export default JobDetail