import { Department, Designation, EvaluationCriterion, KRA } from "@models";
import { AppraisalCycleType } from "@viewModels";
import { BaseModel } from "./baseModel";

export class AppraisalTemplate extends BaseModel {
    name: string;
    remarks: string;
    kRAsTotalPercentage: number;
    evaluationCriteriaTotalPercentage: number;
    cycleType: AppraisalCycleType;
    appraisalTemplateKRAs: AppraisalTemplateKRA[];
    appraisalTemplateEvaluationCriteria: AppraisalTemplateEvaluationCriterion[];
    appraisalTemplateDesignations: AppraisalTemplateDesignation[];
}

export class AppraisalTemplateKRA {
    id: number;
    apprisalTemplateId: number;
    appraisalTemplate: AppraisalTemplate;
    kRAId: number;
    kRA: KRA;
}

export class AppraisalTemplateEvaluationCriterion {
    id: number;
    appraisalTemplateId: number;
    appraisalTemplate: AppraisalTemplate;
    evaluationCriterionId: number;
    evaluationCriterion: EvaluationCriterion;

    expectedTarget: number;
    weight: number;
}

export class AppraisalTemplateDesignation {
    id: number;
    apprisalTemplateId: number;
    appraisalTemplate: AppraisalTemplate;
    departmentId: number;
    department: Department;
    designationId: number | null;
    designation: Designation | null;
}