import { ENDPOINTS, httpService, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { DocumentType, Employee } from "@models";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as XLSX from "xlsx";

interface Props {
  callback?: () => void;
}

const UploadEmployees = ({ callback }: Props) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", data.file[0]);

    await httpService(ENDPOINTS.uploadEmployees).post(formData);

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  const onDownload = async (e: any) => {
    e.preventDefault();

    var readyData = await excelData();
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(readyData ?? []);
    XLSX.utils.book_append_sheet(wb, ws, `${"Sheet 01"}`);
    XLSX.writeFile(wb, `Employee Upload Template.xlsx`);
  };

  const excelData = async () => {
    return [0].map((e) => ({
      Name: "*",
      Employee_Id: "",
      Phone: "*",
      Birth_Date: "*",
      Age_Group: "",
      Job_Grade: "*",
      Email: "",
      Source_Of_Income: "",
      Payroll_group: "*",
      Department: "*",
      Designation: "*",
      Branch: "*",
      Date_Of_Employment: "*",
      Contract_Type: "",
      Gender: "*",
      Sub_Branch: "",
      Salary: "*",
      AnnualLeaveBalance: "*",
      MaritalStatus: "",
      AccountNumber: "",
      PassportNo: "",
      PassportExpiry: "",
      Nationality: "",
    }));
  };

  return (
    <>
      <h3>Upload Employees</h3>
      <hr />
      <div className="d-flex justify-content-start mb-3">
        <a href="!#" style={{ fontSize: "12px" }} onClick={onDownload}>
          Click here to download the template
        </a>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="file">Select File</label>
              <input
                type="file"
                name="file"
                id="file"
                className="form-control"
                ref={register({ required: true })}
              />
              <span className="text-danger">
                {errors.file && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : "Upload"}
        />
      </form>
    </>
  );
};

export default UploadEmployees;
