import {
  ListAllowanceTypes,
  ListDeductionTypes,
  ListDocumentTypes,
  ListOverTimeTypes,
  ListLeaveTypes,
  ListShifts,
  AddProfile,
  ListIncomeSources,
} from "@components";

const ConfigurationsDashboard = () => {
  return (
    <div className="content container-fluid">
      <div className="page-header">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="page-title">Configurations</h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">Configurations Dashboard</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row">
        <ListAllowanceTypes />
        <div className="col"></div>
        <ListDeductionTypes />
      </div>
      <div className="row">
        <ListDocumentTypes />
        <div className="col"></div>
        <ListOverTimeTypes />
      </div>
      <div className="row">
        <ListLeaveTypes />
        <div className="col"></div>
        <ListShifts />
      </div>
      <div className="row">
        <ListIncomeSources/>
        <div className="col"></div>
        <AddProfile />
      </div>
    </div>
  );
};

export default ConfigurationsDashboard;
