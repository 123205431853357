export enum AccessLevel {
    Branch = 'branch',
    HQ = 'hQ'
}

export enum TypeOfDeduction {
    OneTimePayment = "oneTimePayment",
    Installment = "installment",
    Recurrent = "Recurrent"
}

export enum AllowanceStatus {
    Ongoing = "Ongoing",
    Finished = "Finished",
    Cancelled = "Cancelled"
}

export enum RecurrentDeductionType {
    Percentage = "Percentage",
    Value = "Value"
}

export enum JobType {
    Full_Time = 'full_Time',
    Part_Time = 'part_Time',
    Remote = 'remote'
}

export enum JobEducationLevel {
    Any = 'any',
    Under_Graduate = 'under_Graduate',
    Post_Graduate = 'post_Graduate',
    PhD = 'PhD',
    Secondary_Certificate = 'Secondary_Certificate'
}

export enum JobApplicationStatus {
    Pending = "pending",
    Rejected = "rejected",
    Short_Listed = "short_Listed",
    Hired = "hired"
}

export enum PayrollStatus {
    Pending = "pending",
    Approved = "approved"
}

export enum DeductionDetailStatus {
    Pending = "pending",
    Deducted = "deducted"
}

export enum TypeOfAllowance {
    Recurrent = "recurrent",
    Bonus = "bonus"
}

export enum AllowanceDetailStatus {
    Pending = "pending",
    Paid = "paid"
}

export enum AppraisalCycleType {
    Weekly = "weekly",
    Monthly = "monthly",
    Quarterly = "quarterly",
    Yearly = "yearly"
}

export enum AppraisalCycleStatus {
    Draft = "draft",
    New = "new",
    Ongoing = "ongoing",
    Completed = "completed"
}

export enum GoalStatus {
    Draft = "draft",
    New = "new",
    Ongoing = "ongoing",
    Completed = "completed"
}

export enum DeductionDetailType {
    Deduction = "Deduction",
    TopUp = "TopUp"
}