import { Branch, Deduction } from "@models";
import { BaseModel } from "./baseModel";
import { PayrollPreviewVM } from "@viewModels";
import { PaginatedResult } from "@api";

export class PayrollPrep extends BaseModel {
    branchId: number;
    branch: Branch;
    forMonth: string;
    payrollList: PayrollPreviewVM[] = [];
    employeeDeductions: { [key: string]: PaginatedResult<Deduction>; };
}