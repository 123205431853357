import { ENDPOINTS, httpService } from "@api";
import { Branch } from "@models";
import { BranchSelection } from "@widgets";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

interface Props {
  callback?: () => void;
  branch?: Branch;
}

const AddBranch: React.FC<Props> = ({ callback, branch }) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedBranchId, setSelectedBranchId] = useState(0);

  const onSubmit = async (data: any, e: any) => {
    if (selectedBranchId === 0) return;

    setIsLoading(true);

    const Nbranch: Partial<Branch> = {
      branchNumber: data.branchNumber,
      location: data.location,
      payrollBranchId: selectedBranchId,
    };
    if (branch?.id) {
      Nbranch.id = branch.id;
      const res = await httpService(ENDPOINTS.branches).update(
        branch.id,
        Nbranch
      );
    } else {
      const res = await httpService(ENDPOINTS.branches).post(Nbranch);
      if (res.status === 201) {
        Swal.fire({
          icon: "success",
          text: "New Branch has been successfully registered.",
          showConfirmButton: false,
        });
      }
    }

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>Add New Branch</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Branch Location</label>
              <div className="col-12">
                <input
                  type="text"
                  defaultValue={branch?.location ?? ""}
                  className="form-control"
                  placeholder="Enter location"
                  name="location"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Branch Number</label>
              <div className="col-12">
                <input
                  type="text"
                  defaultValue={branch?.branchNumber ?? ""}
                  className="form-control"
                  placeholder="Enter Branch Number"
                  name="branchNumber"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Payroll Branch</label>
              <BranchSelection
                register={register}
                errors={errors}
                isSelectedOption={branch?.payrollBranchId}
                returnValue={(id: number) => setSelectedBranchId(id)}
              />
            </div>
          </div>
        </div>

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : "Register"}
        />
      </form>
    </>
  );
};

export default AddBranch;
