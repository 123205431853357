import { AuthViewModel, UserInfoViewModel } from "@viewModels";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { CURRENT_BACKEND, ENDPOINTS, getUserInfo, httpService } from "@api";
import jwtDecode from "jwt-decode";
import crypto from "crypto-js";
import { SECRET_KEY } from "@shared";
import { CustomeLogo } from "@components";

const Login = () => {
  const [urlParams] = useSearchParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [credentials, setCredentials] = useState<AuthViewModel>(
    new AuthViewModel()
  );
  const [errMsg, setErrMsg] = useState<string | null>(null);

  useEffect(() => {
    (document.querySelector(".username") as HTMLInputElement).focus();
  }, []);

  try {
    var currentUser = getUserInfo().token;

    if (currentUser) {
      return <Navigate to={`/`} />;
    }
  } catch (error) {
    localStorage.removeItem("sys_user");
    console.log({ error });
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!credentials.userName || !credentials.password) return;

    setIsLoading(true);

    var credential: AuthViewModel = {
      userName: credentials.userName,
      password: credentials.password,
      app: "HR",
    };

    const res = await httpService(ENDPOINTS.login).post(credential);
    if (res && res.status === 200) {
      const userInfo = decodeToken(res?.data?.token);

      // Encrypt
      var ciphertext = crypto.AES.encrypt(
        JSON.stringify(userInfo),
        SECRET_KEY
      ).toString();

      localStorage.setItem("sys_user", JSON.stringify(ciphertext));

      const returnUrl = urlParams.get("returnUrl");
      if (returnUrl && returnUrl.length > 0) {
        // navigate(returnUrl, { replace: true });
        window.location.replace(returnUrl);
      } else {
        window.location.replace("/");
        // navigate("/", { replace: true });
      }

      // window.location.assign('/');
      setCredentials(new AuthViewModel());
    }

    setIsLoading(false);
  };

  const decodeToken = (token: string) => {
    var decode: any = jwtDecode(token);

    var userInfo: UserInfoViewModel = {
      userId: decode?.userId,
      userName: decode?.userName,
      roleId: decode?.roleId,
      roleName: decode?.roleName,
      accessLevel: decode?.accessLevel,
      branchId: decode?.branchId,

      token: token,
    };

    return userInfo;
  };

  return (
    <div
      className="account-page"
      style={{
        background:
          " linear-gradient(150deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)",
        height: "100vh",
      }}
    >
      <div className="main-wrapper">
        <div className="account-content">
          <div className="container">
            {/* Account Logo */}
            <div
              // className="account-logo"
              style={{ textAlign: "center", marginBottom: "25px" }}
            >
              <a href="javascript:void(0);">
                <CustomeLogo />
              </a>
            </div>
            {/* /Account Logo */}
            <div className="account-box">
              <div className="account-wrapper">
                <h3 className="account-title">Login</h3>
                <p className="account-subtitle">Access to our dashboard</p>
                {/* Account Form */}
                <form>
                  <div className="form-group">
                    <label>User Name</label>
                    <input
                      className="form-control username"
                      type="text"
                      placeholder="username..."
                      onChange={(e) => {
                        setCredentials((prev) => ({
                          ...prev,
                          userName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col">
                        <label>Password</label>
                      </div>
                    </div>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="password..."
                        id="password"
                        onChange={(e) => {
                          setCredentials((prev) => ({
                            ...prev,
                            password: e.target.value,
                          }));
                        }}
                      />
                      <span className="fa fa-eye-slash" id="toggle-password" />
                    </div>
                  </div>
                  <div className="form-group text-center">
                    <button
                      onClick={(e) => onSubmit(e)}
                      className="btn btn-primary account-btn"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Login"}
                    </button>
                  </div>
                  <div className="account-footer">
                    <p>All rights reserved @SomDigital Technologies</p>
                    <p>Environment: {CURRENT_BACKEND}</p>
                  </div>
                </form>
                {/* /Account Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
