import { ENDPOINTS, httpService } from "@api";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  deductionId: number;
  callback?: () => void;
}

const PayDeduction = ({ deductionId, callback }: Props) => {
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);

    var obj = {
      deductionId,
      amount: data.amount,
    };

    const res = await httpService(ENDPOINTS.clearDeduction).update(
      deductionId,
      obj
    );
    if (res.status === 204) callback?.();

    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h5>Clear Deduction</h5>
      <hr />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="form-group">
            <label>Amount</label>
            <div className="col-12">
              <input
                type="number"
                step={0.01}
                className="form-control"
                name="amount"
                ref={register({ required: true })}
              />
            </div>
            <span className="text-danger">
              {errors.amount && <span>This field is required</span>}
            </span>
          </div>
        </div>
        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : "Pay"}
        />
      </form>
    </>
  );
};

export default PayDeduction;
