import { ENDPOINTS, httpService, logout } from "@api";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
interface props {
  userId: number;
  isResetPassword?: boolean;
}

const ChangePassword = ({ userId, isResetPassword = false }: props) => {
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);
    setErrMsg("");

    if (data.newPassword !== data.repeatNewPassword) {
      setErrMsg("Repeat password field must match new password filed.");
    }

    let obj = {
      userId: userId,
      newPassword: data.newPassword,
      oldPassword: null,
    };

    if (!isResetPassword) obj.oldPassword = data.oldPassword;

    const res = await httpService(ENDPOINTS.changePassword).update(userId, obj);
    if (res.status === 204) {
      Swal.fire({
        toast: true,
        icon: "info",
        showConfirmButton: false,
        text: "The password has been changed successfully",
      });

      !isResetPassword && logout();
    }
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      {isResetPassword ? (
        <h3>Reset User Password</h3>
      ) : (
        <h3>Change Password</h3>
      )}

      <hr />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-3">
          <div className="col d-flex justify-content-start">
            {errMsg && <small className="text-danger">* {errMsg}</small>}
          </div>
        </div>

        {!isResetPassword && (
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Old Password</label>
                <div className="col-12">
                  <input
                    type="oldPassword"
                    className="form-control"
                    placeholder="Enter old password"
                    name="oldPassword"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>New Password</label>
              <div className="col-12">
                <input
                  type="newPassword"
                  className="form-control"
                  placeholder="Enter new password"
                  name="newPassword"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Repeat New Password</label>
              <div className="col-12">
                <input
                  type="repeatNewPassword"
                  className="form-control"
                  placeholder="Repeat new password"
                  name="repeatNewPassword"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
        </div>

        <input
          type="submit"
          className="btn btn-danger"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : "Register"}
        />
      </form>
    </>
  );
};

export default ChangePassword;
