import { useState, useEffect, Dispatch, SetStateAction } from "react";

export const useLocalStorage = <T,>(key: string, defaultValue: any) => {
    const [value, setValue] = useState(() => {
        let currentValue;

        try {
            currentValue = JSON.parse(
                localStorage.getItem(key) || String(defaultValue)
            );
        } catch (error) {
            currentValue = defaultValue;
        }

        return currentValue;
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [value, key]);

    const keyExists = (): T | null => {
        const thereExists = JSON.parse(localStorage.getItem(key) ?? "null");
        if (thereExists === "null") return null;
        return thereExists as T;
    }

    const removeKey = () => {
        localStorage.removeItem(key);
    }

    return [value, setValue, keyExists, removeKey] as [T, Dispatch<SetStateAction<T>>, () => T | null, () => void];
};

