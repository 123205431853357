import { ENDPOINTS, PaginatedResult, PagingOptions, httpService } from "@api";
import { AddKRAGroup } from "@components";
import { useFetch } from "@hooks";
import { KRA } from "@models";
import { KRAGroup } from "core/models/KRAGroup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

interface Props {
  selectedKRA?: KRA;
  callback?: () => void;
}

const NewKRA = ({ selectedKRA, callback }: Props) => {
  const MySwal = withReactContent(Swal);

  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const fetchGroups = useFetch<PaginatedResult<KRAGroup>>(
    {
      endPoint: ENDPOINTS.kraGroups,
      ignorePagination: true,
    },
    new PaginatedResult()
  );

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);

    const kra: Partial<KRA> = {
      name: data.name,
      groupId: data.groupId,
    };

    if (selectedKRA?.id) {
      kra.id = selectedKRA.id;
      const res = await httpService(ENDPOINTS.kras).update(selectedKRA.id, kra);
    } else {
      const res = await httpService(ENDPOINTS.kras).post(kra);
      if (res.status === 201) {
        Swal.fire({
          icon: "success",
          text: "New KRA has been successfully registered.",
          showConfirmButton: false,
        });
      }
    }

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  const addGroupClickHandler = (e: any) => {
    e.preventDefault();

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: <AddKRAGroup />,
    });
  };

  return (
    <>
      <h4>Add New KRA</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Key Result Area</label>
              <div className="col-12">
                <input
                  defaultValue={selectedKRA?.name}
                  type="text"
                  className="form-control"
                  placeholder="Enter payroll group name"
                  name="name"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Select Group</label>{" "}
              <a
                href="#/"
                className="h6 text-decoration-underline mx-3"
                onClick={(e) => addGroupClickHandler(e)}
              >
                + add unit
              </a>
              <div className="col-12">
                <select
                  className="form-control select"
                  name="groupId"
                  ref={register({ required: true })}
                >
                  <option></option>
                  {fetchGroups?.data?.items?.map((u, i) => (
                    <option
                      key={i}
                      value={u.id}
                      selected={u.id === selectedKRA?.groupId}
                    >
                      {u.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={
            isLoading
              ? "Please wait..."
              : selectedKRA?.id
              ? "Update"
              : "Register"
          }
        />
      </form>
    </>
  );
};

export default NewKRA;
