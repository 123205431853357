import { ENDPOINTS, httpService, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { Employee } from "@models";
import { ComplexHeader, Table } from "@shared";
import { FetchEmployeesVM } from "@viewModels";
import React, { useState } from "react";

interface Props {
  templateId: number;
  cycleId: number;
  callback?: () => void;
}

const GenerateAppraisals = ({ templateId, cycleId, callback }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const fetchEmployees = useFetch<FetchEmployeesVM[]>(
    {
      endPoint: ENDPOINTS.designationsEmployees,
      id: `${templateId}/${cycleId}`,
    },
    []
  );

  const headers: ComplexHeader[] = [
    { key: "employeeId", title: "employee Id" },
    { key: "name", title: "Full Name" },
    { key: "location", title: "Branch" },
    { key: "departmentName", title: "Department" },
    { key: "designationName", title: "Designation" },
  ];

  const onGenerateClickHanlder = async () => {
    setIsLoading(true);
    let obj = {
      id: cycleId,
      employeeIds: fetchEmployees.data.map((e) => e.id),
    };
    const res = await httpService(ENDPOINTS.generateAppraisals).post(obj);
    if (res.status === 201) callback?.();

    setIsLoading(false);
  };

  return (
    <>
      <h3>Generate Appraisals</h3>
      <hr />

      <Table
        class="table table-striped table-hover mb-0"
        data={fetchEmployees?.data ?? []}
        headers={headers}
        isFetchingPage={fetchEmployees.isFetching}
        showCounter
      />

      <div className="d-flex justify-content-end mt-5">
        <button
          className="btn btn-dark"
          disabled={isLoading}
          onClick={() => onGenerateClickHanlder()}
        >
          {isLoading ? "Please wait..." : "Generate Appraisals"}
        </button>
      </div>
    </>
  );
};

export default GenerateAppraisals;
