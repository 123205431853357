import { ENDPOINTS, httpService, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { Allowance, AllowanceType } from "@models";
import { useEmployeeStore, usePayrollStore } from "@stores";
import { AllowanceStatus, FetchAllowancesDTO } from "@viewModels";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  employeeId: number;
  allowance?: FetchAllowancesDTO;
  callback?: () => void;
}

const AddAllowance: React.FC<Props> = ({ callback, allowance, employeeId }) => {
  const clearSotrage = usePayrollStore((s) => s.clearStorage);
  const calculateSalary = useEmployeeStore((s) => s.calculateSalary);

  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [allowances, setAllowances] = useState<AllowanceType[]>([]);

  const fetchAllowanceTypes = useFetch<PaginatedResult<AllowanceType>>(
    { endPoint: ENDPOINTS.allowanceTypes },
    new PaginatedResult<AllowanceType>()
  );

  useEffect(() => {
    if (fetchAllowanceTypes?.data?.items?.length > 0) {
      setAllowances(
        fetchAllowanceTypes?.data?.items?.filter((a) => a.name !== "Overtime")
      );
    }
  }, [fetchAllowanceTypes.isFetching]);

  const onSubmit = async (data: any, e: any) => {
    if (!employeeId) return;

    setIsLoading(true);

    let dType: Partial<Allowance> = {
      employeeId: employeeId,
      allowanceTypeId: data.allowanceTypeId,
      amount: +data.amount,
      status: AllowanceStatus.Ongoing,
    };

    if (allowance?.id) {
      dType.id = allowance.id;
      const res = await httpService(ENDPOINTS.allowances).update(
        allowance.id,
        dType
      );
      if (res.status === 204) {
        clearSotrage();
      }
    } else {
      await httpService(ENDPOINTS.allowances).post(dType);
    }

    calculateSalary(employeeId);

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>{allowance?.id ? "Edit" : "Add"} Allowance</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="form-group">
            <label>allowance Type</label>
            <select
              className="form-control select"
              name="allowanceTypeId"
              ref={register({ required: true })}
            >
              <option></option>
              {allowances?.map((r: AllowanceType, i: any) => {
                return (
                  <option
                    key={i}
                    value={r.id}
                    selected={allowance?.allowanceTypeId === r.id}
                  >
                    {r.name} - {r.type}
                  </option>
                );
              })}
            </select>
            <span className="text-danger">
              {errors.allowanceTypeId && <span>This field is required</span>}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>amount</label>
              <div className="col-12">
                <input
                  type="number"
                  defaultValue={allowance?.amount ?? ""}
                  className="form-control"
                  name="amount"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.amount && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={
            isLoading
              ? "Please wait..."
              : fetchAllowanceTypes.isFetching
              ? "Loading..."
              : allowance?.id
              ? "Update"
              : "Register"
          }
        />
      </form>
    </>
  );
};

export default AddAllowance;
