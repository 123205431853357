import { ENDPOINTS, PaginatedResult } from "@api";
import { AddJobOffer, JobDetail } from "@components";
import { useFetch } from "@hooks";
import { JobOffer } from "@models";
import { Action, ComplexHeader, Table, TableVerticalConfigs } from "@shared";
import { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ListJobOffers = () => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchData = useFetch<PaginatedResult<JobOffer>>(
    { endPoint: ENDPOINTS.jobOffers, reload: reload },
    new PaginatedResult<JobOffer>()
  );

  useEffect(() => {}, [fetchData?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "title", title: "Title" },
    { key: "location", title: "location" },
    { key: "type", title: "type", formatter: (type) => type.replace("_", " ") },
    {
      key: "levelOfEducation",
      title: "level Of Education",
      formatter: (levelOfEducation) => levelOfEducation.replace("_", " "),
    },
    {
      key: "isPublished",
      title: "Status",
      formatter: (isPublished) => (isPublished ? "Published" : "Pending"),
      renderer: {
        condition: (u: JobOffer) => u.isPublished === true,
        ifTrue: "badge badge-success",
        ifFalse: "badge badge-danger",
      },
    },
    { key: "expiryDate", title: "expires @", format: "date" },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (d: JobOffer) => {
        MySwal.fire({
          showConfirmButton: false,
          allowOutsideClick: false,
          showCloseButton: true,
          width: 1000,
          html: (
            <AddJobOffer
              offer={d}
              callback={() =>
                setReload((prev) => (prev === undefined ? true : !prev))
              }
            />
          ),
        });
      },
      title: "Edit",
      color: "dark",
    },
    {
      key: "",
      actionType: "badge",
      click: (d: JobOffer) => {
        MySwal.fire({
          showConfirmButton: false,
          allowOutsideClick: false,
          showCloseButton: true,
          width: 1200,
          html: <JobDetail offer={d} />,
        });
      },
      title: "Details",
      color: "primary",
    },
    {
      key: "",
      actionType: "badge",
      click: (d: JobOffer) => {
        navigate(`/recruitment/applicants/${d.id}`, { state: d.title });
      },
      title: "Applications",
      color: "success",
    },
  ];

  const onButtonClick = () => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 1000,
      html: (
        <AddJobOffer
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Job Offers</h3>
        </div>
        <div className="col-auto float-end ms-auto">
          <button
            className="btn btn-sm btn-primary rounded-circle m-1"
            onClick={onButtonClick}
          >
            <FiPlus />
          </button>
        </div>
      </div>

      <div className="row mt-3">
        <Table
          actions={actions}
          class="table table-striped table-hover mb-0"
          data={fetchData?.data ?? []}
          headers={headers}
          isFetchingPage={fetchData.isFetching}
          showCounter
          onPageChange={handlePageChange}
          paginationClass="row mt-3"
        />
      </div>
    </div>
  );
};

export default ListJobOffers;
