import { BASE_CURRENCY, ENDPOINTS, httpService, Operation, PaginatedResult } from "@api";
import { PaperHeader } from "@components";
import { ComplexHeader, ExportData, Filterable, Table, TableVerticalConfigs } from "@shared";
import { AllowanceStatus, FetchAllowancesDTO, TypeOfAllowance } from "@viewModels";
import { BranchSelection } from "@widgets";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const AllowancesReport = () => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();

  const [data, setData] = useState<PaginatedResult<FetchAllowancesDTO>>(new PaginatedResult<FetchAllowancesDTO>());
  const [selectedBranchId, setSelectedBranchId] = useState<number>();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (selectedBranchId) {
      queryStrings.set("branchId", selectedBranchId?.toString());
      queryStrings.set("page", '0');
      queryStrings.set("size", '-1');
      navigate({ search: `?${queryStrings.toString()}` });

      (async function () {
        setIsFetching(true);
        const res = await httpService(ENDPOINTS.allowances, queryStrings, {ignorePagination:true}).getAll();
        if (res.status === 200) {
          setData(res.data);
        }
        setIsFetching(false);
      })();
    } else {
      navigate({ search: `` });
    }
  }, [selectedBranchId, queryStrings]);

  const headers: ComplexHeader[] = [
    { key: "employeeCode", title: "Id" },
    { key: "employeeName", title: "Name" },
    { key: "allowanceTypeName", title: "Allowance Type" },
    {
      key: "amount",
      title: "amount",
      format: "currency",
      currency: () => BASE_CURRENCY,
      total: { format: "currency", currency: BASE_CURRENCY },
    },
    {
      key: "type",
      title: "type",
      renderer: {
        condition: (u: FetchAllowancesDTO) => u.type === TypeOfAllowance.Bonus,
        ifTrue: "badge badge-info",
        ifFalse: "badge badge-dark",
      },
    },
    {
      key: "status",
      title: "status",
      renderer: {
        condition: (u: FetchAllowancesDTO) => u.status === AllowanceStatus.Finished,
        ifTrue: "badge badge-success",
        ifFalse: "badge badge-danger",
      },
    },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const filters: Filterable<FetchAllowancesDTO>[] = [
    { key: "createdAt", title: "created at", format: "dateRange" },
    {
      key: "employeeName",
      title: "employee name",
      format: "text",
      operation: Operation.like,
    },
    {
      key: "status",
      title: "Status",
      format: "select",
      data:[
        ...Object.keys(AllowanceStatus)?.map((d) => ({
          id: d,
          name: d.replace("_", " ").capitalize(),
        })),
      ]
    },
  ];

  const onExport: ExportData = {
    data: () => excelData(),
    fileName: `Allowances Report - ${new Date().toLocaleDateString()}`,
  };

  const excelData = async () => {
    queryStrings.set("size", (0).toString());

    const res = await httpService(ENDPOINTS.allowances, queryStrings).getAll();
    if (res.status === 200) {
      return (res.data as PaginatedResult<FetchAllowancesDTO>)?.items;
    }

    return [];
  };

  const onPrint = (element: HTMLElement) => {
    var subElement = element.querySelectorAll(":scope > div")[0];
    subElement.classList.remove("d-none");

    var subElement02 = element.querySelectorAll(":scope > div")[1];
    subElement02.classList.add("d-none");

    let table = element.getElementsByTagName("table")[0];
    table.classList.remove("table-striped");
  };

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="d-flex justify-content-between mb-1">
          <div className="col-sm-12 col-lg-4">
            <h3 className="page-title">Allowances Report</h3>
          </div>
        </div>
      </div>

      <div className="col-12 mt-3" id="reportcontents">
        <PaperHeader title="Allowances Report" />
        <div className="row mb-3">
          <div className="col-sm-12 col-md-3">
            <div className="row gap-2">
              <BranchSelection className="col" returnValue={(val: number) => setSelectedBranchId(val)} />
            </div>
          </div>
          <div className="col-auto float-end ms-auto">
            <TableVerticalConfigs
              filters={filters}
              exportAsXSLS={onExport}
              onPrint={{
                ref: "reportcontents",
                prePrint: onPrint,
              }}
            />
          </div>
        </div>
        <Table
          class="table table-striped table-hover mb-0"
          data={data?.items}
          headers={headers}
          isFetchingPage={isFetching}
          showCounter
          onPageChange={handlePageChange}
          paginationClass="row mt-3"
          showTotals
        />
      </div>
    </div>
  );
};

export default AllowancesReport;
