import { ENDPOINTS, getUserInfo, httpService, logout } from "@api";
import { ChangePassword } from "@components";
import { APP_TITLE } from "@shared";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Header = () => {
  const MySwal = withReactContent(Swal);
  const user = getUserInfo();

  const [fileData, setFileData] = useState<string>("");
  const [companyName, setCompanyName] = useState("");
  const [imageHeight, setImageHeight] = useState<number>(0);

  useEffect(() => {
    const storedFileData = localStorage.getItem("fileData"); // Retrieve the file data from local storage
    if (storedFileData) {
      setFileData((prev) => (prev = storedFileData));
    } else {
      (async function () {
        const res = await httpService(ENDPOINTS.companyLogo).getBlob();
        if (res.status === 200) {
          const reader = new FileReader();
          reader.onload = () => {
            const dataUrl = reader.result as string;
            setFileData(dataUrl);
            localStorage.setItem("fileData", dataUrl); // Save the file data in local storage
          };
          reader.readAsDataURL(res.data as Blob);
        }
      })();
    }
  }, [fileData]);

  useEffect(() => {
    const storedFileData = localStorage.getItem("companyName"); // Retrieve the file data from local storage
    if (storedFileData) {
      setFileData(storedFileData);
    } else {
      (async function () {
        const res = await httpService(ENDPOINTS.companyProfiles).getAll();
        if (res.status === 200) {
          localStorage.setItem("companyName", res?.data?.name); // Save the file data in local storage
          setCompanyName(res?.data?.name);
        }
      })();
    }
  }, [companyName]);

  const changePassword = (e: any) => {
    e.preventDefault();

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: <ChangePassword userId={user.userId} />,
    });
  };

  const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const imgElement = event.target as HTMLImageElement;
    setImageHeight(imgElement.height);
  };

  return (
    <div className="header">
      {/* Logo */}
      <div className="header-left">
        <NavLink to={"/"} className="logo">
          <img
            src={fileData}
            onLoad={handleImageLoad}
            style={{
              maxWidth: imageHeight > 50 ? "40%" : "100%",
              height: "auto",
            }}
          />
        </NavLink>
      </div>
      {/* /Logo */}
      <a id="toggle_btn" href="javascript:void(0);">
        <span className="bar-icon">
          <span />
          <span />
          <span />
        </span>
      </a>
      {/* Header Title */}
      <div className="page-title-box">
        {/* <h3>Amal Banka - HR</h3> */}
        <h3>{companyName} - Human Resource MIS</h3>
      </div>
      {/* /Header Title */}
      <a id="mobile_btn" className="mobile_btn" href="#sidebar">
        <i className="fa fa-bars" />
      </a>
      {/* Header Menu */}
      <ul className="nav user-menu">
        {/* Search */}
        {/* <li className="nav-item">
          <div className="top-nav-search">
            <a href="javascript:void(0);" className="responsive-search">
              <i className="fa fa-search" />
            </a>
            <form action="search.html">
              <input
                className="form-control"
                type="text"
                placeholder="Search here"
              />
              <button className="btn" type="submit">
                <i className="fa fa-search" />
              </button>
            </form>
          </div>
        </li> */}
        <li className="nav-item dropdown has-arrow main-drop">
          <a
            href="/#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
          >
            <span>{user?.userName || "User"}</span>
          </a>
          <div className="dropdown-menu">
            {/* <a className="dropdown-item" href="profile.html">
              My Profile
            </a> */}
            <a
              className="dropdown-item"
              href="!#"
              onClick={(e) => changePassword(e)}
            >
              Change Passowrd
            </a>
            <a
              className="dropdown-item"
              href="javascript:void(0)"
              onClick={logout}
            >
              Logout
            </a>
          </div>
        </li>
      </ul>
      {/* /Header Menu */}
      {/* Mobile Menu */}
      <div className="dropdown mobile-user-menu">
        <a
          href="#"
          className="nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v" />
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <a className="dropdown-item" href="profile.html">
            My Profile
          </a>
          <a className="dropdown-item" href="settings.html">
            Settings
          </a>
          <a className="dropdown-item" href="index.html">
            Logout
          </a>
        </div>
      </div>
      {/* /Mobile Menu */}
    </div>
  );
};

export default Header;
