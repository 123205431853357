import { ENDPOINTS, httpService } from "@api";
import { PaperHeader } from "@components";
import { useFetch } from "@hooks";
import { ComplexHeader, ExportData, Table, TableVerticalConfigs } from "@shared";
import { BranchSelection } from "@widgets";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const AllowancesSummaryReport = () => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [headers, setheaders] = useState<ComplexHeader[]>([]);
  const [selectedBranchId, setSelectedBranchId] = useState<number | null>(null);
  const [data, setData] = useState<{}[]>([]);

  useEffect(() => {
    if (selectedBranchId) {
      queryStrings.set("branchId", selectedBranchId?.toString());
      navigate({ search: `?${queryStrings.toString()}` });

      (async function () {
        setIsLoading(true);
        const res = await httpService(ENDPOINTS.allowancesSummary, queryStrings).getAll();
        if (res.status === 200) {
          setData(res.data);

          if (res?.data?.length > 0) {
            var x: ComplexHeader[] = [];

            Object.keys(res?.data[0]).forEach((d) => {
              x.push({ key: d, title: d.replaceAll("_", " ") });
            });

            setheaders(x);
          }
        }
        setIsLoading(false);
      })();
    } else {
      navigate({ search: `` });
    }
  }, [queryStrings, selectedBranchId]);

  const onExport: ExportData = {
    data: () => Promise.resolve(data),
    fileName: `Allowances Summary Report - ${new Date().toLocaleDateString()}`,
  };

  const onPrint = (element: HTMLElement) => {
    var subElement = element.querySelectorAll(":scope > div")[0];
    subElement.classList.remove("d-none");

    var subElement02 = element.querySelectorAll(":scope > div")[1];
    subElement02.classList.add("d-none");

    let table = element.getElementsByTagName("table")[0];
    table.classList.remove("table-striped");
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="d-flex justify-content-between mb-1">
          <div className="col-sm-12 col-lg-4">
            <h3 className="page-title">Allowances Summary Report</h3>
          </div>
        </div>
      </div>

      <div className="col-12 mt-3" id="reportcontents">
        <PaperHeader title="Allowances Report" />
        <div className="row mb-3">
          <div className="col-sm-12 col-md-3">
            <div className="row gap-2">
              <BranchSelection className="col" showAllOptions returnValue={(val: number) => setSelectedBranchId(val)} />
            </div>
          </div>
          <div className="col-auto float-end ms-auto">
            {headers.length > 0 && (
              <TableVerticalConfigs
              exportAsXSLS={onExport}
              onPrint={{
                ref: "reportcontents",
                prePrint: onPrint,
              }}
              />
            )}
          </div>
        </div>
        {headers.length > 0 && (
          <Table
            class="table table-striped table-hover mb-0"
            data={data ?? []}
            headers={headers}
            // actions={actions}
            isFetchingPage={isLoading}
            showCounter
            hidePagination
          />
        )}
      </div>
    </div>
  );
};

export default AllowancesSummaryReport;
