import { BranchDaily, BranchMonthly, EmployeeMonthly } from "@components";
import React, { useEffect, useState } from "react";

type AttendanceComponent = "Branch" | "BranchMonthly" | "Employee";

const AttendanceMain = () => {
  const [selectedComp, setSelectedComp] =
    useState<AttendanceComponent>("Branch");

  const radioClickHandler = (e: any) => {
    const value = e.target.value;
    setSelectedComp(value);
  };

  return (
    <div className="content container-fluid">
      <div className="d-flex justify-content-between">
        <div className="col-4">
          <h3 className="page-title">Attendance Report</h3>
        </div>
        <div className="offset-2 col-6">
          <div className="col">
            <p>Report type:</p>
          </div>

          <div className="form-check col">
            <input
              className="form-check-input"
              type="radio"
              name="filter"
              id="Branch"
              onClick={radioClickHandler}
              value="Branch"
              defaultChecked={true}
            />
            <label className="form-check-label" htmlFor="Branch">
              Branch Daily
            </label>
          </div>
          <div className="form-check col">
            <input
              className="form-check-input"
              type="radio"
              name="filter"
              id="BranchMonthly"
              onClick={radioClickHandler}
              value="BranchMonthly"
            />
            <label className="form-check-label" htmlFor="BranchMonthly">
              Branch Monthly
            </label>
          </div>
          <div className="form-check col">
            <input
              className="form-check-input"
              type="radio"
              name="filter"
              id="Employee"
              onClick={radioClickHandler}
              value="Employee"
            />
            <label className="form-check-label" htmlFor="Employee">
              Employee Monthly
            </label>
          </div>
        </div>
      </div>
      {/* <div className="col-8 offset-2 mt-3"> */}
      {selectedComp === "Branch" && <BranchDaily />}
      {selectedComp === "BranchMonthly" && <BranchMonthly />}
      {selectedComp === "Employee" && <EmployeeMonthly />}
      {/* </div> */}
    </div>
  );
};

export default AttendanceMain;
