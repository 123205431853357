import { ENDPOINTS, httpService } from "@api";
import React, { useEffect, useState } from "react";

const CustomeLogo = () => {
  const [fileData, setFileData] = useState<string>("");
  const [imageHeight, setImageHeight] = useState<number>(0);

  useEffect(() => {
    const storedFileData = localStorage.getItem("fileData"); // Retrieve the file data from local storage
    if (storedFileData) {
      setFileData(storedFileData);
    } else {
      (async function () {
        const res = await httpService(ENDPOINTS.companyLogo).getBlob();
        if (res.status === 200) {
          const reader = new FileReader();
          reader.onload = () => {
            const dataUrl = reader.result as string;
            setFileData(dataUrl);
            localStorage.setItem("fileData", dataUrl); // Save the file data in local storage
          };
          reader.readAsDataURL(res.data as Blob);
        }
      })();
    }
  }, []);

  const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const imgElement = event.target as HTMLImageElement;
    setImageHeight(imgElement.height);
  };

  return (
    <img
      src={fileData}
      onLoad={handleImageLoad}
      style={{
        maxWidth: imageHeight > 100 ? "50%" : "100%",
        height: "auto",
      }}
    />
  );
};

export default CustomeLogo;
