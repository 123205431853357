import { CustomeLogo, SubmitApplication } from "@components";
import { JobOffer } from "@models";
import { DateHelpers } from "@shared";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { NavLink, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const JobDescription = () => {
  const MySwal = withReactContent(Swal);

  const offer = useLocation().state as JobOffer;

  const [hasApplied, setHasApplied] = useState(false);

  useEffect(() => {
    let storage = localStorage.getItem("application");
    if (typeof storage === "string") {
      let res = JSON.parse(storage) as [];
      if (!res.isEmpty() && res.find((res: any) => res?.id === offer.id)) {
        setHasApplied(true);
      }
    }
  }, [offer]);

  const onButtonClick = () => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 900,
      html: <SubmitApplication offerId={offer.id} />,
    });
  };

  return (
    <div
      // className="main-wrapper"
      style={{
        background:
          " linear-gradient(150deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)",
        minHeight: "100vh",
      }}
    >
      <div className="row justify-content-center">
        <div className="d-flex justify-content-center mt-5">
          <NavLink to={"/recruitments"}>
            <CustomeLogo />
          </NavLink>
        </div>

        <div className="row mt-5">
          <div className="col-sm-12 col-md-8">
            <div className="card">
              <div className="card-body">
                <ReactQuill
                  readOnly
                  theme="bubble"
                  value={offer?.description}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Summary</h3>
                <hr />
                <div className="row">
                  <span className="col-12 mt-1">
                    Title: <strong>{offer.title}</strong>{" "}
                  </span>
                  <span className="col-12 mt-1">
                    posted At:{" "}
                    <strong>
                      {DateHelpers.forwardSlashedDate(offer.createdAt)}
                    </strong>{" "}
                  </span>
                  <span className="col-12 mt-1">
                    Expires At:{" "}
                    <strong>
                      {" "}
                      {DateHelpers.forwardSlashedDate(offer.expiryDate)}
                    </strong>{" "}
                  </span>
                  <span className="col-12 mt-1">
                    Location: <strong>{offer.location}</strong>{" "}
                  </span>
                  <span className="col-12 mt-1">
                    level Of Education:{" "}
                    <strong>{offer.levelOfEducation.replace("_", " ")}</strong>{" "}
                  </span>
                  <span className="col-12 mt-1">
                    Type: <strong>{offer.type.replace("_", " ")}</strong>{" "}
                  </span>
                </div>

                {hasApplied ? (
                  <div className="d-flex justify-content-center mt-5">
                    <span className="text-info">
                      Your application for this offer has been submitted.
                    </span>
                  </div>
                ) : (
                  <div className="d-flex justify-content-end mt-5">
                    <button className="btn btn-danger" onClick={onButtonClick}>
                      Apply Now
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDescription;
