import { ENDPOINTS, getUserInfo, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { Branch } from "@models";
import { AccessLevel } from "@viewModels";
import { useEffect, useState } from "react";
import { DeepMap, FieldError, FieldValues } from "react-hook-form";

interface props {
  isSelectedOption?: number | null;
  className?: string;
  returnValue?: (val: number) => void;
  endpoint?: ENDPOINTS;
  register?: any;
  errors?: DeepMap<FieldValues, FieldError>;
  showAllOptions?: boolean; // to show 'select all' option
}

const BranchSelection = ({
  isSelectedOption,
  register,
  className,
  returnValue,
  endpoint,
  errors,
  showAllOptions = false,
}: props) => {
  const user = getUserInfo();

  const [branches, setBranches] = useState<PaginatedResult<Branch>>(new PaginatedResult());

  const fetchBranches = useFetch<PaginatedResult<Branch>>(
    { endPoint: endpoint ?? ENDPOINTS.payrollBranches, ignorePagination: true },
    new PaginatedResult<Branch>()
  );

  useEffect(() => {
    if (fetchBranches?.data?.items?.length > 0) {
      // if (
      //   user.accessLevel.toLowerCase() === AccessLevel.Branch.toLowerCase() &&
      //   endpoint !== ENDPOINTS.payrollBranches
      // ) {
      //   setBranches((prev) => ({
      //     ...prev,
      //     items: fetchBranches?.data?.items?.filter(
      //       (f) => f.id === +user?.branchId
      //     ),
      //   }));
      // } else {
      setBranches(fetchBranches?.data);
      // }
    }
  }, [fetchBranches.isFetching]);

  // useEffect(() => {
  //   console.log("selected is", isSelectedOption);
  // }, [isSelectedOption]);

  const handleDropDownSelection = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    // console.log("selected is: ", +e.target.value);

    returnValue?.(+e.target.value);
  };

  return (
    <>
      <select
        className={`form-control select ${className}`}
        name="branchId"
        ref={typeof register !== "undefined" ? register({ required: true }) : null}
        onChange={(e) => handleDropDownSelection(e)}
      >
        {fetchBranches.isFetching ? (
          <option>Loading...</option>
        ) : (
          <>
            {showAllOptions ? (
              <>
                <option>Select Payroll Branch</option>
                <option value="-1">Show All</option>
              </>
            ) : (
              <option>Select Payroll Branch</option>
            )}
            {branches?.items?.map((r: Branch, i: any) => {
              return (
                <option
                  selected={typeof isSelectedOption === "number" ? isSelectedOption === r.id : false}
                  key={i}
                  value={r.id}
                >
                  {r.location} - {r.branchNumber}
                </option>
              );
            })}
          </>
        )}
      </select>
      <span className="text-danger">{errors && errors.branchId && <span>This field is required</span>}</span>
    </>
  );
};

export default BranchSelection;
