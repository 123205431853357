import { ENDPOINTS, PaginatedResult, PagingOptions, httpService } from "@api";
import { ListAllowances, ListDeductions } from "@components";
import { Allowance, Deduction } from "@models";
import { MyBarLoader } from "@shared";
import { AllowanceStatus, DeductionsRef, PayrollPreviewVM } from "@viewModels";
import React, { useRef, useState } from "react";
import Swal from "sweetalert2";

type toBeUpdatedFields = {
  allowances: number;
  deductions: number;
  deductionsRef: DeductionsRef[];
  empDeductionsRef: PaginatedResult<Deduction>;
};

interface Props {
  payrollPreview: PayrollPreviewVM;
  callback?: (
    allowances: number,
    deductions: number,
    deductionsRef: DeductionsRef[],
    empDeductionsRef: PaginatedResult<Deduction>
  ) => void;
  employeeDeductions?: {
    [key: string]: PaginatedResult<Deduction>;
  };
}

const ModifyEmployeePayroll: React.FC<Props> = ({
  callback,
  payrollPreview,
  employeeDeductions,
}) => {
  // const [modification, setModification] = useState<toBeUpdatedFields>({
  //   allowances: payrollPreview.allowances,
  //   deductions: payrollPreview?.deductionRefs.reduce((prev, curr) => {
  //     return prev + curr.monthlyInstallment;
  //   }, 0),
  //   deductionsRef: payrollPreview.deductionRefs,
  //   empDeductionsRef: new PaginatedResult<Deduction>(),
  // });
  // const [updatedDeductions, setUpdatedDeductions] = useState<Deduction[]>([]);
  const [validating, setValidating] = useState(false);
  const [errMsg, setErrMsg] = useState<string | null>(null);

  const modificationx = useRef<toBeUpdatedFields>({
    allowances: payrollPreview.allowances,
    deductions: payrollPreview?.deductionRefs.reduce((prev, curr) => {
      return prev + curr.monthlyInstallment;
    }, 0),
    deductionsRef: payrollPreview.deductionRefs,
    empDeductionsRef: new PaginatedResult<Deduction>(),
  });

  const updatedDeductionsx = useRef<Deduction[]>([]);

  const updateEmpData = async () => {
    if (updatedDeductionsx.current.length === 0) return;
    setValidating(true);
    setErrMsg(null);

    // First: check if the new deductions made are feasible.
    var obj = {
      empId: payrollPreview.id,
      deductions: updatedDeductionsx.current?.map((d) => ({
        deductionId: d.id,
        amount:
          modificationx.current.deductionsRef.find((dref) => dref.id === d.id)
            ?.monthlyInstallment ?? d.amount,
      })),
    };

    var res = await httpService(ENDPOINTS.checkEmpDeductionFeasibility).post(
      obj
    );
    if (res?.status === 200 && res?.data?.status === true) {
      // if the new deductions are feasible, proceed.
      let temp = new PaginatedResult<Deduction>();
      temp.items = [...updatedDeductionsx.current];

      modificationx.current.empDeductionsRef = temp;
      callback?.(
        modificationx.current.allowances,
        modificationx.current.deductions,
        modificationx.current.deductionsRef,
        modificationx.current.empDeductionsRef
      );
    } else {
      setErrMsg(res?.data?.message);
    }

    // setValidating(false);
  };

  const modifyDeductionsRef = (
    values: Record<string, any>,
    deductions: Deduction[]
  ) => {
    var newDeductionsRef: DeductionsRef[] = [];
    Object.keys(values).map((k) => {
      return newDeductionsRef.push({ id: +k, monthlyInstallment: +values[k] });
    });
    modificationx.current.deductions = newDeductionsRef.reduce((prev, curr) => {
      return prev + curr.monthlyInstallment;
    }, 0);
    modificationx.current.deductionsRef = newDeductionsRef;

    modificationx.current = { ...modificationx.current };
    updatedDeductionsx.current = [...deductions];
  };

  const addDeductionsOptions = (): PagingOptions => {
    var options = new PagingOptions();
    options.filter<Deduction>((f) => f.gt("ballance", 0));
    return options;
  };

  const addAllowancessOptions = (): PagingOptions => {
    var options = new PagingOptions();
    options.filter<Allowance>((f) => f.eq("status", AllowanceStatus.Ongoing));
    return options;
  };

  return (
    <>
      <h4>Employee Payroll Details - {payrollPreview.name}</h4>
      <hr />
      {/* <div className="d-flex justify-content-left">
                <div className="col">
                    <small>Employee Name: <strong>{payrollPreview.name}</strong></small>
                </div>
            </div> */}
      <div className="row">
        <h4>Employee Allowances</h4>
        <ListAllowances
          employeeId={payrollPreview.id}
          showInPopUp
          options={addAllowancessOptions()}
          showActionButtons={false}
        />
      </div>
      <div className="row mt-5">
        <h4>Employee Deductions</h4>
        {validating ? (
          <MyBarLoader />
        ) : (
          <ListDeductions
            employeeId={payrollPreview.id}
            showInPopUp
            hideInputsInTable={false}
            callback={modifyDeductionsRef}
            options={addDeductionsOptions()}
            showActionButtons={false}
            employeeDeductions={employeeDeductions}
          />
        )}
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label>Total Allowances</label>
            <div className="col-12">
              <input
                type="text"
                disabled
                value={modificationx.current?.allowances?.toCurrency()}
                className="form-control"
                // onChange={(e) =>
                //   setModification((prev) => ({
                //     ...prev,
                //     allowances: +e.target.value,
                //   }))
                // }
              />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label>Total Deductions</label>
            <div className="col-12">
              <input
                disabled
                type="text"
                value={modificationx.current?.deductions?.toCurrency()}
                className="form-control"
                // onChange={(e) => setModification(prev => ({ ...prev, deductions: +e.target.value }))}
              />
            </div>
          </div>
        </div>
        <div className="col-3 mt-1 pt-1">
          <input
            type="button"
            className="btn btn-dark mt-3"
            value={validating ? "Validating..." : "Modify"}
            onClick={updateEmpData}
          />
        </div>
      </div>
      {errMsg && (
        <div className="d-flex justify-content-start">
          <span className="text-danger">{errMsg}</span>
        </div>
      )}
    </>
  );
};

export default ModifyEmployeePayroll;
