import { ENDPOINTS, httpService } from "@api";
import { DeductionType } from "@models";
import { RecurrentDeductionType, TypeOfDeduction } from "@viewModels";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  callback?: () => void;
  deductionType?: DeductionType;
}

const AddDeductionType: React.FC<Props> = ({ callback, deductionType }) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedDeductionType, setSelectedDeductionType] = useState<
    string | null
  >(null);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);

    let dType: Partial<DeductionType> = {
      name: data.name,
      type: data.type,
      recurrentDeductionType: data?.recurrentDeductionType ?? null,
    };

    if (deductionType?.id) {
      dType.id = deductionType.id;
      await httpService(ENDPOINTS.deductionTypes).update(
        deductionType.id,
        dType
      );
    } else {
      await httpService(ENDPOINTS.deductionTypes).post(dType);
    }

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>{deductionType?.id ? "Edit" : "Add"} Deduction Type</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Deduction Name</label>
              <div className="col-12">
                <input
                  type="text"
                  defaultValue={deductionType?.name ?? ""}
                  className="form-control"
                  name="name"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.name && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>deduction Type</label>
              <select
                className="form-control select"
                name="type"
                ref={register({ required: true })}
                onChange={(e) => setSelectedDeductionType(e.target.value)}
              >
                <option></option>
                {Object.keys(TypeOfDeduction).map((r: string, i) => {
                  return (
                    <option
                      selected={
                        deductionType?.type.toLowerCase() === r.toLowerCase()
                      }
                      key={i}
                      value={r}
                    >
                      {r}
                    </option>
                  );
                })}
              </select>
              <span className="text-danger">
                {errors.type && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>

        {selectedDeductionType === TypeOfDeduction.Recurrent && (
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Recurrent deduction Type</label>
                <select
                  className="form-control select"
                  name="recurrentDeductionType"
                  ref={register({ required: true })}
                >
                  <option></option>
                  {Object.keys(RecurrentDeductionType).map((r: string, i) => {
                    return (
                      <option
                        selected={
                          (
                            deductionType?.recurrentDeductionType ?? " "
                          ).toLowerCase() === r.toLowerCase()
                        }
                        key={i}
                        value={r}
                      >
                        {r}
                      </option>
                    );
                  })}
                </select>
                <span className="text-danger">
                  {errors.recurrentDeductionType && (
                    <span>This field is required</span>
                  )}
                </span>
              </div>
            </div>
          </div>
        )}

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : "Register"}
        />
      </form>
    </>
  );
};

export default AddDeductionType;
