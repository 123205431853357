import { ENDPOINTS, httpService } from "@api";
import { useState } from "react";
import { useForm } from "react-hook-form";

const AddKRAGroup = () => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);

    const obj = {
      name: data.name,
    };

    const res = await httpService(ENDPOINTS.kraGroups).post(obj);

    setIsLoading(false);
    e.target.reset();
  };
  return (
    <>
      <h4>Add KRA Group</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label> Group Name</label>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
        </div>

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : "Submit"}
        />
      </form>
    </>
  );
};

export default AddKRAGroup;
